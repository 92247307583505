import { PropsWithChildren } from 'react'

import { ParentSideBar } from '@features/navigation/side-bar'
import PageLayout from '@templates/page.layout'
import { useAuthContext } from '@/lib/auth/useAuth'
import { ChildrenProvider } from '@/lib/student/children-provider'
import { ParentNavigationBar } from '@features/navigation/navigation-bar'
import { FeedbackFloatingButton } from '@features/feedback/feedback-floating-button'
import EditProfileModal from '@features/account/edit-profile-modal'
import { TermsModal } from '@features/account/terms-modal'
import { useLocales } from '@/core/hooks'
import AlertStatus from '@molecules/alert-status'

export default function ParentLayout({ children }: PropsWithChildren) {
  const { t } = useLocales()
  const { isAgreedTerms } = useAuthContext()

  return (
    <ChildrenProvider>
      <PageLayout sidebar={<ParentSideBar />} bottom={<ParentNavigationBar />}>
        {children}
        <FeedbackFloatingButton className="hidden md:block" />
        <TermsModal open={isAgreedTerms === false} />
        <EditProfileModal
          title={t('components.modals.welcome_update_profile.heading')}
          desc={''}
          cancelText={t('components.buttons.skip')}
          submitText={t('components.buttons.update_continue')}
          topContent={
            <>
              <AlertStatus
                className="mb-1"
                icon={false}
                type="warning"
                message={t('components.notification.found_information.heading')}
                desc={t('components.modals.welcome_update_profile.description')}
              />
              <p className="mb-2 text-xs text-muted-foreground">
                {t('components.notification.found_information.description')}
              </p>
            </>
          }
          open={isAgreedTerms === false}
        />
      </PageLayout>
    </ChildrenProvider>
  )
}
