import { USER_ROLE } from '@/lib/auth/auth-types'
import AuthGuard from '@/lib/auth/permissions/auth-guard'
import FullPageLoaderTemplate from '@templates/full-page-loader.template'
import { Suspense } from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import TeacherLayout from './teacher.layout'

export const TEACHER_HOMEPAGE = '/teacher/dashboard'
export const TEACHER_ROUTES: RouteObject[] = [
  {
    path: 'teacher',
    element: (
      <AuthGuard allowedRoles={[USER_ROLE.TEACHER]}>
        <TeacherLayout>
          <Suspense fallback={<FullPageLoaderTemplate />}>
            <Outlet />
          </Suspense>
        </TeacherLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={TEACHER_HOMEPAGE} />,
      },
      {
        path: 'dashboard',
        lazy: () => import('./dashboard/dashboard.page'),
      },
      {
        path: 'news',
        // lazy: () => import("./feed/feed.page"),
        children: [
          {
            path: '',
            lazy: () => import('./news/news.page'),
          },
          {
            path: ':id',
            lazy: () => import('./news/news-detail/news-detail.page'),
          },
        ],
      },
      {
        path: 'policy-documents',
        children: [
          {
            path: '',
            lazy: () => import('./policy-documents/policy-documents.page'),
          },
          {
            path: ':id',
            lazy: () =>
              import('./policy-documents/document-detail/document-detail.page'),
          },
        ],
      },
      {
        path: 'menu',
        children: [
          {
            path: '',
            lazy: () => import('./menu/menu.page'),
          },
          {
            path: ':id',
            lazy: () => import('./menu/menu-detail/menu-detail.page'),
          },
        ],
      },
      {
        path: 'activities',
        // lazy: () => import("./feed/feed.page"),
        children: [
          {
            path: '',
            lazy: () => import('./activities/activities.page'),
          },
          {
            path: ':id',
            lazy: () => import('./activities/page-detail/page-detail.page'),
          },
        ],
      },
      {
        path: 'timetable',
        lazy: () => import('./timetable/timetable.page'),
      },
      {
        path: 'attendance',
        // lazy: () => import("./feed/feed.page"),
        children: [
          {
            path: '',
            lazy: () => import('./attendance/attendance.page'),
          },
          {
            path: 'classes',
            lazy: () =>
              import('./attendance/attendance-classes/attendance-classes.page'),
          },
          {
            path: 'classes/new-record',
            lazy: () =>
              import(
                './attendance/attendance-class-record-detail/create-record-detail.page'
              ),
          },
          {
            path: 'classes/:id',
            lazy: () =>
              import(
                './attendance/attendance-class-record-detail/update-record-detail.page'
              ),
          },
          {
            path: 'subjects',
            lazy: () =>
              import(
                './attendance/attendance-subjects/attendance-subjects.page'
              ),
          },
          {
            path: 'subjects/new-record',
            lazy: () =>
              import(
                './attendance/attendance-subject-record-detail/create-record-detail.page'
              ),
          },
          {
            path: 'subjects/:id',
            lazy: () =>
              import(
                './attendance/attendance-subject-record-detail/update-record-detail.page'
              ),
          },
        ],
      },
      {
        path: 'student-profile/:id/:code/:tab/*',
        lazy: () => import('./student-profile/student-profile.page'),
      },
      {
        path: 'student-profile',
        element: <Navigate replace to={'/teacher/school-classes'} />,
      },
      {
        path: 'school-classes',
        children: [
          {
            path: '',
            lazy: () => import('./school-classes/school-classes.page'),
          },
          {
            path: ':id',
            lazy: () =>
              import('./school-classes/school-class/school-class.page'),
          },
        ],
      },
      {
        path: 'settings',
        lazy: () => import('./settings/settings.page'),
      },
    ],
  },
]
