import { useFrappeGetCall } from 'frappe-react-sdk'

import { FRAPPE_APIS } from '../api.config'
import { SISClassFeed } from '@/types/SIS/SISClassFeed'
import { FrappeMeta } from '@/types/Extends/FrappeMeta'
import { useEffect } from 'react'
import { Period } from '@/types/Extends/Timetable'
import { SISSchoolClass } from '@/types/SIS/SISSchoolClass'
import { SchoolClassSimpleExtend } from '@/types/Extends/SchoolClass'

export const useGetSchoolClassesByUser = (skip?: boolean) => {
  const { data, error, isLoading, isValidating, mutate } = useFrappeGetCall<{
    message: SchoolClassSimpleExtend[]
  }>(
    FRAPPE_APIS.GET_SCHOOL_CLASSES_BY_USER.METHOD_STRING,
    {},
    !skip ? `${FRAPPE_APIS.GET_SCHOOL_CLASSES_BY_USER.SWR_KEY}`:null,
  )

  return { error, data: data?.message , isLoading, isValidating }
}
