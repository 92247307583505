import { SISRoleType, USER_ROLE } from '@/lib/auth/auth-types'
import AuthGuard from '@/lib/auth/permissions/auth-guard'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import AdminLayout from './admin.layout'
import { Suspense } from 'react'
import FullPageLoaderTemplate from '@templates/full-page-loader.template'
import HasAccess from '@/lib/auth/permissions/has-access'
import NoPermissionState from '@features/states/no-permisson-state'

export const ADMIN_HOMEPAGE = '/admin/news'
export const ADMIN_ROUTES: RouteObject[] = [
  {
    path: '/admin',
    element: (
      <AuthGuard allowedRoles={[USER_ROLE.ADMIN]}>
        <AdminLayout>
          <Suspense fallback={<FullPageLoaderTemplate />}>
            <Outlet />
          </Suspense>
        </AdminLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={ADMIN_HOMEPAGE} />,
      },
      {
        path: 'news',
        // lazy: () => import("./feed/feed.page"),
        children: [
          {
            path: '',
            lazy: () => import('./news/news.page'),
          },
          {
            path: ':id',
            lazy: () => import('./news/news-detail/news-detail.page'),
          },
        ],
      },
      {
        path: 'activities',
        // lazy: () => import("./feed/feed.page"),
        element: (
          <HasAccess
            roles={[SISRoleType.SIS_CONTENT_CREATOR]}
            renderAuthFailed={<NoPermissionState />}
          >
            <Outlet />
          </HasAccess>
        ),
        children: [
          {
            path: '',
            lazy: () => import('./activities/activities.page'),
          },
          {
            path: ':id',
            lazy: () => import('./activities/page-detail/page-detail.page'),
          },
        ],
      },
      {
        path: 'policy-documents',
        children: [
          {
            path: '',
            lazy: () => import('./policy-documents/policy-documents.page'),
          },
          {
            path: ':id',
            lazy: () =>
              import('./policy-documents/document-detail/document-detail.page'),
          },
        ],
      },
      {
        path: 'student-profile/:id/:code/:tab/*',
        lazy: () => import('./student-profile/student-profile.page'),
      },
      {
        path: 'student-profile',
        element: <Navigate replace to={'/admin/school-classes'} />,
      },
      {
        path: 'school-classes',
        children: [
          {
            path: '',
            lazy: () => import('./school-classes/school-classes.page'),
          },
          {
            path: ':id',
            lazy: () =>
              import('./school-classes/school-class/school-class.page'),
          },
        ],
      },
      {
        path: 'menu',
        children: [
          {
            path: '',
            lazy: () => import('./menu/menu.page'),
          },
          {
            path: ':id',
            lazy: () => import('./menu/menu-detail/menu-detail.page'),
          },
        ],
      },
      {
        path: 'attendance',
        element: (
          <HasAccess
            roles={[SISRoleType.SIS_ATTENDANCE_MANAGER]}
            renderAuthFailed={<NoPermissionState />}
          >
            <Outlet />
          </HasAccess>
        ),
        children: [
          {
            path: '',
            element: <Navigate replace to="/admin/attendance/classes" />,
          },
          {
            path: 'classes',
            lazy: () =>
              import('./attendance/attendance-classes/attendance-classes.page'),
          },
          {
            path: 'classes/new-record',
            lazy: () =>
              import(
                './attendance/attendance-class-record-detail/create-record-detail.page'
              ),
          },
          {
            path: 'classes/:id',
            lazy: () =>
              import(
                './attendance/attendance-class-record-detail/update-record-detail.page'
              ),
          },
        ],
      },
      {
        path: 'timetable',
        lazy: () => import('./timetable/timetable.page'),
      },
      {
        path: 'settings',
        lazy: () => import('./settings/settings.page'),
      },
    ],
  },
]
